import React from "react";
import Layout from "../../components/layout";
import SEO from "../../components/seo";
import ServiceList from "../../components/service-list";

const Services = () => (
  <Layout>
    <SEO title="Our Dental Services" />
    <ServiceList />
  </Layout>
);

export default Services;
