import React from "react";
import { Breadcrumb, BreadcrumbItem } from "../Breadcrumb";
import PageContainer from "../PageContainer";
import {
  Container,
  ImageContainer,
  ContnetContainer,
  Row,
  Column,
  Card,
  StyledImage,
  Title,
  Description,
  LearnMore,
  LearnMoreWrapper,
  Arrow,
} from "./styles";
import { useData } from "./useData";

const ServiceList = () => {
  const list = useData();

  return (
    <>
      <Breadcrumb pageTitle="What We Do">
        <BreadcrumbItem Link="/what-we-do" Text="What We Do" />
      </Breadcrumb>
      <PageContainer>
        <Container>
          <Row>
            {list.map((item) => {
              return (
                <Column key={item.id}>
                  <Card to={item.to}>
                    <ImageContainer>
                      <StyledImage fluid={item.mainImage} />
                    </ImageContainer>
                    <ContnetContainer>
                      <Title>{item.title}</Title>
                      <Description>{item.description}</Description>
                      <LearnMoreWrapper>
                        <LearnMore>Learn More</LearnMore>
                        <Arrow></Arrow>
                      </LearnMoreWrapper>
                    </ContnetContainer>
                  </Card>
                </Column>
              );
            })}
          </Row>
        </Container>
      </PageContainer>
    </>
  );
};

export default ServiceList;
